
import {defineComponent} from "vue";
import {Form, Input, message, Select, Radio, Checkbox, Row, Col} from "ant-design-vue";
import {customParameters} from "@/api/terminalList";

const {Item} = Form;
const {Option} = Select;
let flagDisable = 0;
let {Group} = Radio;
let {Group:CheckboxGroup} = Checkbox;

export default defineComponent({
  components: {
    Form,
    FormItem: Item,
    Input,
    Select,
    Option,
    aRadio: Radio,
    aRadioGroup: Group,
    aCheckbox: Checkbox,
    aCheckboxGroup: CheckboxGroup,
    Row,
    Col
  },
  data() {
    return {
      checkCustom: [],
    };
  },
  props: {
    datalist: {
      type: Object,
      defaul: {},
    },
  },
  methods: {
    handleDisable(item) {
      if (flagDisable < (this as any).datalist.extraList.length * 2) {
        flagDisable++;
        if ((this as any).datalist[item.extraCode] == undefined || (this as any).datalist[item.extraCode] == '') {
          return false;
        }
        if (this.$route.params.id == "new") {
          return false;
        }
        if (item.isEdit === 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    //计算多选框值
    computedCheckboxValue(str){
      if (str) {
        return str.split(';')
      }
      else{
        return []
      }
    },
    changeChenkBox(checkedValue,extraCode){
      (this as any).datalist[extraCode]=checkedValue.join(';')
    }
  },
  unmounted() {
    flagDisable = 0;
  },
  mounted() {
        //  
        if ((this as any).datalist.extraList) {
          (this as any).datalist.extraList.map((item) => {
          let isrequired = true;
          if (item.isEmpty) {
            isrequired = false;
          }
          (this as any).checkCustom.push([
            {
              required: isrequired,
              trigger: "blur",
              validator: async (rule, value, item) => {
                if (value === undefined) {
                  value = "";
                }
                var value: any = String(value);
                // var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
                var value = value.trim();
                if (value.length < 1 && isrequired) {
                  return Promise.reject("请输入必填项");
                }
              },
            },
            {min: 0, max: 20, message: "最多20字符", trigger: "blur"},
          ]);
        });
        }
  },
});
