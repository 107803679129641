
import { defineComponent, reactive, ref, toRaw } from "vue";
import Basics from "@/components/masterDataDetails/Basics.vue";
import Custom from "@/components/masterDataDetails/Custom.vue";
import Qualification from "@/components/masterDataDetails/Qualification.vue";
import { Form, Button, Input, message } from "ant-design-vue";
import {
  customParameters,
  addTerminal,
  editTerminal,
  getTerminalDetails,
} from "@/api/terminalList.js";
import { terminalDetailsRules } from "@/rules/rules.js";
import { useRouter } from "vue-router";

const { Item } = Form;

export default defineComponent({
  data() {
    return {
      showCustom: false, //自定义字段未加载结束之前不显示
    };
  },
  components: {
    Basics,
    Custom,
    Qualification,
    Form,
    Button,
    FormItem: Item,
    Input,
  },
  setup() {
    const router = useRouter();
    const formRef = ref();
    const formState: any = reactive({
      terminalName: "",
      terminalCode: "",
      status: 1,
      province: [],
      town: "",
      address: "",
      baiduLon: "",
      baiduLat: "",
      channelCode: [],
      channelName: [],
      channel: [],
      scaleType: null,
      phone: "",
      doorPhoto: "",
      licensePhoto: "",
      licenseCode: "",
      licenseName: "",
      licenseAddress: "",
      licenseDay: "",
      licenseType: "",
      licenseExpiry: "",
      licenseScope: "",
      terminalExtra: {},
      regionValue: [],
    });
    const rules: any = terminalDetailsRules;
    const onSubmit = () => {
      //去掉前后空格
      Object.keys(formState).forEach((key) => {
        if (typeof formState[key] == "string") {
          formState[key] = formState[key].trim();
        }
      });
      formRef.value
        .validate()
        .then(() => {
          let keepFormState = { ...formState };
          let id = router.currentRoute.value.params.id;
          let province;
          let city;
          let area;
          let provinceCode;
          let cityCode;
          let areaCode;
          let channelCodes = "";
          let channelNames = "";
          if (typeof formState.channelCode == "object") {
            channelCodes =
              formState.channelCode[formState.channelCode.length - 1].value;
          } else {
            channelCodes = formState.channelCode;
          }
          // formState.channelCode.forEach(element => {
          //   channelCodes+=element+"/"
          // });
          // channelCodes =  channelCodes.slice(0,channelCodes.length-1);
          formState.channelName.forEach((element) => {
            channelNames += element + "/";
          });
          channelNames = channelNames.slice(0, channelNames.length - 1);
          if (formState.changeCityList) {
            province = formState.changeCityList[0].name;
            city = formState.changeCityList[1].name;
            area = formState.changeCityList[2].name;
            provinceCode = formState.changeCityList[0].code;
            cityCode = formState.changeCityList[1].code;
            areaCode = formState.changeCityList[2].code;
          } else {
            province = formState.defaultProvince;
            city = formState.defaultCity;
            area = formState.defaultArea;
            provinceCode = formState.provinceCode;
            cityCode = formState.cityCode;
            areaCode = formState.areaCode;
          }

          //for (let i = 0; i < toRaw(formState).extraList.length; i++) {
          //  let key = toRaw(formState).extraList[i].extraCode;
          //  toRaw(formState).terminalExtra[key] = formState[key];
          //}
          //for (let i = 0; i < toRaw(formState).extraList.length; i++) {
          //  delete formState.extraList[i].extraCode;
          //}
          //let terminalExtra = JSON.stringify(formState.terminalExtra);
          //let params: any = JSON.parse(JSON.stringify(formState))

          let params: any = JSON.parse(JSON.stringify(formState));
          for (let i = 0; i < toRaw(params).extraList.length; i++) {
            let key = toRaw(params).extraList[i].extraCode;
            toRaw(params).terminalExtra[key] = params[key];
          }
          for (let i = 0; i < toRaw(params).extraList.length; i++) {
            delete params.extraList[i].extraCode;
          }
          let terminalExtra = JSON.stringify(params.terminalExtra);
          params.provinceCode = provinceCode;
          params.cityCode = cityCode;
          params.areaCode = areaCode;
          params.province = province;
          params.city = city;
          params.area = area;
          params.terminalExtraStr = terminalExtra;
          params.baiduLon = sessionStorage.getItem("lng");
          params.baiduLat = sessionStorage.getItem("lat");
          params.channelCode = channelCodes;
          params.channelName = channelNames;
          delete params.terminalExtra;
          delete params.doorPhotoUpload;
          delete params.extraList;
          delete params.licensePhotoUpload;
          delete params.imgFlagDoor;
          delete params.imgFlagLicense;
          delete params.defaultProvince;
          delete params.defaultCity;
          delete params.defaultArea;
          delete params.regionValue;
          delete params.channel;
          delete params.changeCityList;
          //新增终端
          if (id === "new") {
            params.status = 1;
            addTerminal({ ...params }).then((res) => {
              //
              if (res.data.success) {
                // message.success(`${res.data.data}`);
                router.push("/masterData/terminal/list");
              }
              // else {
              //   location.reload();
              // }
            });
          }
          //编辑终端
          else {
            if (params.doorPhoto) {
              if (params.doorPhoto.includes("http")) {
                delete params.doorPhoto;
              }
            }
            if (params.licensePhoto) {
              if (params.licensePhoto.includes("http")) {
                delete params.licensePhoto;
              }
            }
            params.id = id;
            editTerminal({ ...params })
              .then((res) => {
                //
                if (res.data.success) {
                  // message.success(`${res.data.data}`);
                  router.push("/masterData/terminal/list");
                } else {
                  params = keepFormState;
                }
              })
              .catch(() => {
                params = keepFormState;
              });
          }
        })
        .catch(() => {
          message.warning("录入信息不规范请修改");
        });
    };
    const cancel = () => {
      history.go(-1);
    };
    return {
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      formState,
      rules,
      onSubmit,
      cancel,
    };
  },
  methods: {
    //验证图片是否有效
    checkImg(src) {
      if (src) {
        if (src.includes(".jp") || src.includes(".png")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== "new") {
      customParameters("terminal").then((res: any) => {
        (this as any).showCustom = true;
        (this as any).formState.extraList = res.data.data.filter((item) => {
          return item.status;
        });
        getTerminalDetails(this.$route.params.id).then((res: any) => {
          let result = res.data.data;
          for (var attr in result.extraField) {
            result[attr] = result.extraField[attr];
          }
          this.formState.terminalName = result.terminalName;
          this.formState.terminalCode = result.terminalCode;
          this.formState.status = result.status;
          this.formState.province = [result.province, result.city, result.area];
          this.formState.regionValue = [
            result.province,
            result.city,
            result.area,
          ];
          this.formState.town = result.town;
          this.formState.terminalOriginCode = result.terminalOriginCode;
          this.formState.terminalOriginMemo = result.terminalOriginMemo;
          this.formState.address = result.address;
          this.formState.baiduLon = result.baiduLon + "";
          this.formState.baiduLat = result.baiduLat + "";
          sessionStorage.setItem("lng", result.baiduLon + "");
          sessionStorage.setItem("lat", result.baiduLat + "");
          this.formState.channelCode = result.channelCode;
          this.formState.channelName = result.channelName.split("/");
          this.formState.scaleType = result.scaleType;
          this.formState.phone = result.phone;
          this.formState.licenseCode = result.licenseCode;
          this.formState.licenseName = result.licenseName;
          this.formState.licenseAddress = result.licenseAddress;
          this.formState.licenseDay = result.licenseDay;
          this.formState.licenseType = result.licenseType;
          this.formState.licenseExpiry = result.licenseExpiry;
          this.formState.licenseScope = result.licenseScope;
          this.formState.defaultProvince = result.province;
          this.formState.defaultCity = result.city;
          this.formState.defaultArea = result.area;
          this.formState.provinceCode = result.provinceCode;
          this.formState.cityCode = result.cityCode;
          this.formState.areaCode = result.areaCode;
          /*if (result.terminalExtra) {
            this.formState.terminalExtra = JSON.parse(result.terminalExtra);
          }*/
          for (let i = 0; i < (this as any).formState.extraList.length; i++) {
            //
            let key = (this as any).formState.extraList[i].extraCode;
            if (key === "radio" || key === "checkbox") {
              this.formState[key] = result[key].split(";");
            } else {
              this.formState[key] = result[key];
            }
          }

          this.formState.licensePhoto = result.licensePhoto;
          this.formState.doorPhoto = result.doorPhoto;
          this.formState.imgFlagLicense = this.checkImg(result.licensePhoto);
          this.formState.imgFlagDoor = this.checkImg(result.doorPhoto);
          // this.formState.imgFlagDoor=true
          // this.formState.imgFlagLicense=true
          //
          // this.formState.imgFlagDoor = true;
          // this.formState.imgFlagLicense = true;
          //
          //
        });
      });
    } else {
      //获取扩展字段
      customParameters("terminal").then((res: any) => {
        //
        (this as any).formState.extraList = res.data.data.filter((item) => {
          return item.status;
        });
        (this as any).showCustom = true;
        //
      });
    }
  },
});
