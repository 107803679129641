<template>
  <!-- 省市区联级 -->
  <div>
    <!-- {{formState}} -->
    <Cascader
      style="width: 100%"
      v-model:value="formState.regionValue"
      :options="options"
      placeholder="请选择..."
      :load-data="loadData"
      change-on-select
      @blur="blurRegionValue"
      @change="change"
      :disabled="!isEdit"
      :allowClear="false"
    />
  </div>
</template>
<script >
import { Cascader } from 'ant-design-vue'
import axios from 'axios'
import { getProvince, getCity, getArea } from '@/api/terminalList.js'
export default {
  components: {
    Cascader
  },
  data() {
    return {
      options: [],
      cityCurrent: 0
    }
  },
  props: {
    formState: {
      type: Object,
      defaul: {
        regionValue: []
      }
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    change(value, selectedOptions) {
      sessionStorage.setItem('areaCode', selectedOptions[selectedOptions.length - 1].code) //存储区县code
      this.$emit('myEvent', 'regional')
    },
    blurRegionValue() {
      if (this.cityCurrent !== 1) {
        this.cityCurrent = 0
      }
    },
    //省市区懒加载
    loadData(selectedOptions) {
      let targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true // load options lazily
      let keepOption
      if (targetOption.level === 1) {
        axios.get(`/api/base/v1/areaselect/city/find?provinceCode=${targetOption.code}`).then((res) => {
          targetOption.loading = false
          let newOptions = res.data.data.map((item) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
              isLeaf: false,
              level: item.level
            }
          })

          targetOption.children = newOptions
          this.options = [...this.options]
          keepOption = [...newOptions]
          this.cityCurrent = this.cityCurrent + 1
        })
      }
      if (targetOption.level === 2) {
        let flag = this.options.filter((i) => {
          return i.level == targetOption.level
        }).length
        if (flag) {
          this.cityCurrent = 0
          this.loadData(selectedOptions)
          return
        }
        axios.get(`/api/base/v1/areaselect/area/find?cityCode=${targetOption.code}`).then((res) => {
          targetOption.loading = false
          let newOptions = res.data.data.map((item) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
              level: item.level,
              isLeaf: true
            }
          })
          targetOption.children = newOptions
          if (keepOption) {
            let areaFlag = keepOption.filter((i) => {
              return i.level == targetOption.level
            }).length

            if (areaFlag) {
              this.cityCurrent = 1
              this.loadData(selectedOptions)
              return
            } else {
              this.options = [...this.options]
              this.cityCurrent = 1
            }
          } else {
            this.options = [...this.options]
            this.cityCurrent = 1
          }
        })
      }
    },
    convertKey(arr, level) {
      level++
      arr.forEach((item) => {
        item.level = level
        if (item['childBrandList'].length != 0) {
          item.level = level
          this.convertKey(item.childBrandList, level)
        }
      })
      return arr
    },
    getFirstList() {
      getProvince().then((res) => {
        let newOptions = res.data.data.map((item) => {
          return {
            ...item,
            value: item.name,
            label: item.name,
            isLeaf: false
          }
        })
        if (this.formState.regionValue) {
          if (this.formState.regionValue.length == 0) {
            this.options = newOptions //容错赋值
          }
        }
        console.log(333,this.formState.regionValue[0]);
        newOptions.forEach((elem) => {
          if (elem.name == this.formState.regionValue[0]) {
            console.log(elem);
            getCity(elem.code).then((res1) => {
              elem.children = res1.data.data.map((item) => {
                return {
                  ...item,
                  value: item.name,
                  label: item.name,
                  isLeaf: false
                }
              })
              elem.children.forEach((element) => {
                if (element.name == this.formState.regionValue[1]) {
                  getArea(element.code).then((res2) => {
                    element.children = res2.data.data.map((item) => {
                      if (item.name == this.formState.regionValue[2]) {
                        sessionStorage.setItem('areaCode', item.code) //存储code供下拉使用
                      }
                      return {
                        ...item,
                        value: item.name,
                        label: item.name,
                        isLeaf: true
                      }
                    })
                    //最终赋值
                    this.options = newOptions
                  })
                }
              })
            })
          }
        })
      })
    }
  },
  created() {
    this.getFirstList()
  }
}
</script>
