import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Basics = _resolveComponent("Basics")!
  const _component_Custom = _resolveComponent("Custom")!
  const _component_Qualification = _resolveComponent("Qualification")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form_item = _resolveComponent("Form-item")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    model: _ctx.formState,
    rules: _ctx.rules,
    ref: "formRef",
    "label-col": { style: { width: '110px' } }
  }, {
    default: _withCtx(() => [
      (_ctx.formState.province.length != 0 || this.$route.params.id == 'new')
        ? (_openBlock(), _createBlock(_component_Basics, {
            key: 0,
            datalist: _ctx.formState
          }, null, 8, ["datalist"]))
        : _createCommentVNode("", true),
      (_ctx.showCustom)
        ? (_openBlock(), _createBlock(_component_Custom, {
            key: 1,
            datalist: _ctx.formState
          }, null, 8, ["datalist"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Qualification, { datalist: _ctx.formState }, null, 8, ["datalist"]),
      _createVNode(_component_Form_item, {
        "wrapper-col": { span: 14, offset: 10 },
        style: {"margin-top":"20px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            style: {"margin-right":"20px","width":"100px","height":"40px"},
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消 ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_Button, {
            style: {"width":"100px","height":"40px"},
            type: "primary",
            onClick: _ctx.onSubmit,
            loading: this.$store.state.loadingStatus
          }, {
            default: _withCtx(() => [
              _createTextVNode("提交 ")
            ]),
            _: 1
          }, 8, ["onClick", "loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}