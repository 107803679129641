
import { defineComponent } from "vue";
import {
  Form,
  Button,
  Input,
  Cascader,
  Select,
  AutoComplete,
  Upload,
  Image,
  message,
  Popover,
} from "ant-design-vue";
import {
  getProvince,
  getCity,
  getArea,
  getTown,
  imageUpload,
  channelSearch,
} from "@/api/terminalList.js";
import Regional from "./Regional.vue";
import axios from "axios";
import {
  PictureOutlined,
  LoadingOutlined,
  CloseOutlined,
  EyeFilled,
  EditFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";

import BaiduMap from "./Baidu.vue";
const { Item } = Form;
const { Option } = Select;

export default defineComponent({
  components: {
    Form,
    Button,
    FormItem: Item,
    Input,
    BaiduMap,
    Cascader,
    Select,
    Option,
    AutoComplete,
    Upload,
    PictureOutlined,
    LoadingOutlined,
    Image,
    message,
    CloseOutlined,
    EyeFilled,
    EditFilled,
    QuestionCircleOutlined,
    Popover,
    Regional,
  },
  props: {
    datalist: {
      type: Object,
      defaul: {},
    },
  },
  data() {
    return {
      options: [],
      cityCurrent: 0,
      townOptions: [],
      imageUrl: "",
      notUploaded: true,
      cacheAreacode: {},
      channel: [],
      channelOptions: [],
      firstImg: "",
      Illusion: true, //假象
      IllusionChannel: true,
      list: ["12000", "12001", "12003"],
    };
  },
  methods: {
    //省市区失去焦点
    blurRegionValue() {
      // if (this.cityCurrent !== 1) {
      //   this.cityCurrent = 0;
      // }
      let cacheAreacode =
        (this as any).datalist.province[2].code ||
        (this as any).datalist.areaCode;
      getTown(cacheAreacode).then((res: any) => {
        this.townOptions = res.data.data.map((item) => {
          return { value: item.name };
        });
      });
    },
    blurChannelValue() {
      if (this.cityCurrent !== 1) {
        this.cityCurrent = 0;
      }
    },
    changeRegionValue(val, array) {
      (this as any).datalist.changeCityList = array;
    },
    handelRegionValue(name, selectedOptions) {
      console.log(selectedOptions);

      let array = selectedOptions.map((item) => {
        return { value: item.channelCode };
      });
      (this as any).datalist.channelCode = array;
      console.log((this as any).datalist.channelCode);

      //
    },
    //渠道懒加载
    loadChannelData(selectedOptions: any) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      let keepOption;
      if (this.cityCurrent === 0) {
        axios
          .get(
            `/api/terminal/v1/channelApi/list?parCode=${targetOption.channelCode}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map((item) => {
              if (item.isHave) {
                return {
                  ...item,
                  value: item.channelName,
                  label: item.channelName,
                  isLeaf: false,
                  level: item.level,
                };
              } else {
                return {
                  ...item,
                  value: item.channelName,
                  label: item.channelName,
                  isLeaf: true,
                  level: item.level,
                };
              }
            });

            targetOption.children = newOptions;
            this.channelOptions = [...this.channelOptions];
            keepOption = [...newOptions];
            this.cityCurrent = this.cityCurrent + 1;
          });
      }
      if (this.cityCurrent === 1) {
        let flag = this.channelOptions.filter((i: any) => {
          return i.level == targetOption.level;
        }).length;
        if (flag) {
          this.cityCurrent = 0;
          this.loadChannelData(selectedOptions);
          return;
        }
        axios
          .get(
            `/api/terminal/v1/channelApi/list?parCode=${targetOption.channelCode}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map((item) => {
              if (item.isHave) {
                return {
                  ...item,
                  value: item.channelName,
                  label: item.channelName,
                  isLeaf: false,
                  level: item.level,
                };
              } else {
                return {
                  ...item,
                  value: item.channelName,
                  label: item.channelName,
                  isLeaf: true,
                  level: item.level,
                };
              }
            });
            targetOption.children = newOptions;
            if (keepOption) {
              let areaFlag = keepOption.filter((i: any) => {
                return i.level == targetOption.level;
              }).length;

              if (areaFlag) {
                this.cityCurrent = 1;
                this.loadChannelData(selectedOptions);
                return;
              } else {
                this.channelOptions = [...this.channelOptions];
                this.cityCurrent = 1;
              }
            }
          });
      }
    },
    //省市区懒加载
    loadData(selectedOptions: any) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      let keepOption;
      if (targetOption.level === 1) {
        axios
          .get(
            `/api/base/v1/areaselect/city/find?provinceCode=${targetOption.code}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map((item) => {
              return {
                ...item,
                value: item,
                label: item.name,
                isLeaf: false,
              };
            });
            targetOption.children = newOptions;
            this.options = [...this.options];
            keepOption = [...newOptions];
            this.cityCurrent = this.cityCurrent + 1;
          });
      }
      if (targetOption.level === 2) {
        let flag = this.options.filter((i: any) => {
          return i.level == targetOption.level;
        }).length;
        if (flag) {
          this.cityCurrent = 0;
          this.loadData(selectedOptions);
          return;
        }
        axios
          .get(
            `/api/base/v1/areaselect/area/find?cityCode=${targetOption.code}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map((item) => {
              return {
                ...item,
                value: item,
                label: item.name,
                isLeaf: true,
              };
            });
            targetOption.children = newOptions;
            let areaFlag =
              keepOption &&
              keepOption.filter((i: any) => {
                return i.level == targetOption.level;
              }).length;
            //
            if (areaFlag) {
              this.cityCurrent = 1;
              this.loadData(selectedOptions);
              return;
            } else {
              this.options = [...this.options];
              this.cityCurrent = 1;
            }
            //
            let array = res.data.data;
            let result = {};
            //处理数据
            for (let i = 0; i < array.length; i++) {
              var a = {
                [array[i].name]: array[i].code,
              };
              // array[i];
              result = { ...result, ...a };
            }
            (this as any).cacheAreacode = { ...this.cacheAreacode, ...result };
          });
      }
    },
    //上传图片
    handleChange(info) {
      // if (info.file.status === "done") {
      //   message.success(`${info.file.name} 图片上传成功`);
      //   //拿到数据
      //   //
      // } else if (info.file.status === "error") {
      //   message.error(`${info.file.name} 图片上传失败`);
      // }
      if (info.fileList.length < 1) {
        this.notUploaded = true;
      }
    },
    //检验图片格式
    beforeUpload(file, fileList) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("上传图片应为PNG或JPG格式,请重新上传", 4);
        fileList.length = 0;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("图片大小应小于5M,请重新上传", 4);
        fileList.length = 0;
      }

      return isJpgOrPng && isLt5M;
    },
    getDate() {
      var date = new Date();
      var seperator1 = "";
      var year = date.getFullYear();
      var month: any = date.getMonth() + 1;
      var strDate: any = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    customRequest(data) {
      const formData = new FormData();
      let name = data.file.name;
      name = name.substring(name.lastIndexOf(".") + 1);
      formData.append("file", data.file);
      formData.append("module", `terminal`);
      formData.append("scene", `terminaldoor`);
      formData.append("format", name);
      imageUpload(formData).then((res) => {
        (this as any).datalist.doorPhoto = res.data.data.fileUrl;
        (this as any).firstImg = res.data.data.absoluteFileUrl;
        this.notUploaded = false;
        setTimeout(() => {
          data.onSuccess();
        }, 0);
      });
    },
    changeImg() {
      (this as any).datalist.imgFlagDoor = false;
      this.notUploaded = true;
    },
    handleChannel() {
      (this as any).datalist.channelName = [];
      this.IllusionChannel = false;
      setTimeout(() => {
        let input: any = document.querySelector(".cascader");
        if (input) {
          input.click();
        }
      }, 0);
    },
    handleIllusion() {
      (this as any).datalist.channelName = [];
      this.Illusion = false;
      setTimeout(() => {
        let input: any = document.querySelector(".cascader");
        if (input) {
          input.click();
        }
      }, 0);
    },
    //预览图片
    previewImg() {
      let photo: any = document.querySelector(".previewPhoto");
      setTimeout(() => {
        if (photo) {
          photo.click();
        }
      }, 0);
    },
    getFirstList() {
      getProvince().then((res: any) => {
        let newOptions = res.data.data.map((item) => {
          return {
            ...item,
            value: item.name,
            label: item.name,
            isLeaf: false,
          };
        });
        if ((this as any).datalist.province) {
          if ((this as any).datalist.province.length == 0) {
            (this as any).options = newOptions; //容错赋值
          }
        }
        newOptions.forEach((elem: any) => {
          if (elem.name == (this as any).datalist.province[0]) {
            axios
              .get(
                `/api/base/v1/areaselect/city/find?provinceCode=${elem.code}`
              )
              .then((res1: any) => {
                elem.children = res1.data.data.map((item) => {
                  return {
                    ...item,
                    value: item.name,
                    label: item.name,
                    isLeaf: false,
                  };
                });
                elem.children.forEach((element: any) => {
                  if (element.name == (this as any).datalist.province[1]) {
                    axios
                      .get(
                        `/api/base/v1/areaselect/area/find?cityCode=${element.code}`
                      )
                      .then((res2: any) => {
                        element.children = res2.data.data.map((item) => {
                          if (item.name == (this as any).datalist.province[2]) {
                            sessionStorage.setItem("areaCode", item.code); //存储code供下拉使用
                          }
                          return {
                            ...item,
                            value: item.name,
                            label: item.name,
                            isLeaf: true,
                          };
                        });
                        //最终赋值
                        this.options = newOptions;
                      });
                  }
                });
              });
          }
        });
      });
    },
    //渠道赋值
    getFirstChannelList() {
      let channelCode = (this as any).datalist.channelCode;
      //获取该list中的 父渠道
      axios
        .get(`/api/terminal/v1/channelApi/listParent?code=${channelCode}`)
        .then((res: any) => {
          this.list = res.data.data;
          let length = this.list.length;
          this.getChannelList(this.channelOptions, length);
        });
    },
    getChannelList(channelOption, length) {
      if (length < 0) {
        this.channelOptions = channelOption;
        return;
      }
      if (length > 1) {
        let code = (this as any).list[this.list.length - length].channelCode;
        let item;
        if (channelOption.length > 0) {
          channelOption.forEach((element: any) => {
            if (element.channelCode === code) {
              axios
                .get(`/api/terminal/v1/channelApi/list?parCode=${code}`)
                .then((res: any) => {
                  item = element;
                  element.children = res.data.data.map((element: any) => {
                    if (element.isHave) {
                      return {
                        ...element,
                        value: element.channelName,
                        label: element.channelName,
                        level: element.level,
                        isLeaf: false,
                      };
                    } else {
                      (this as any).datalist.channelCode = [
                        { value: element.channelCode },
                      ];
                      return {
                        ...element,
                        value: element.channelName,
                        label: element.channelName,
                        level: element.level,
                        isLeaf: true,
                      };
                    }
                  });
                  //  (this as any).channelOption=channelOption
                  length--;
                  this.getChannelList(element.children, length);
                });
            }
          });
        }

        //  this.getChannelList(item,length)
      }
    },
  },
  mounted() {
    this.getFirstList();
    channelSearch({
      parCode: 0,
    }).then((res: any) => {
      let newOptions = res.data.data.map((item) => {
        if (item.isHave) {
          return {
            ...item,
            value: item.channelName,
            label: item.channelName,
            isLeaf: false,
            level: item.level,
          };
        } else {
          return {
            ...item,
            value: item.channelName,
            label: item.channelName,
            isLeaf: true,
            level: item.level,
          };
        }
      });
      this.channelOptions = newOptions;
      this.getFirstChannelList();
    });
  },
  created() {},
});
