<template>
  <div id="mapContainer" class="baiduMap"></div>
</template>
<script>
import { defineComponent } from "vue";
// import { message } from "ant-design-vue";
export default defineComponent({
  components: {},
  data() {
    return {
      flag: 0,
    };
  },
  props: {
    address: {
      type: String,
      defaul: "天安门",
    },
    provinceList: {
      type: Array,
      defaul: ["", "", ""],
    },
    town: {
      type: String,
      defaul: "",
    },
    baiduLon: {
      type: String,
      defaul: "116",
    },
    baiduLat: {
      type: String,
      defaul: "39",
    },
  },
  methods: {
    bdMap(city = "天安门") {
      //创建地图
      let map = new BMap.Map("mapContainer");
      map.clearOverlays();
      function sendposition(lngvalue, latvalue) {
        lngvalue = lngvalue.toFixed(6);
        latvalue = latvalue.toFixed(6);
        let lng = document.querySelector(".lng");
        let lat = document.querySelector(".lat");
        lng.value = lngvalue;
        lat.value = latvalue;
        sessionStorage.setItem("lng", lngvalue);
        sessionStorage.setItem("lat", latvalue);
      }
      // map.addControl(new BMap.NavigationControl());
      map.addControl(new BMap.ScaleControl());
      map.addControl(
        new BMap.NavigationControl({
          anchor: BMAP_ANCHOR_TOP_RIGHT,
          type: BMAP_NAVIGATION_CONTROL_SMALL,
        })
      );

      map.enableScrollWheelZoom(true); //允许鼠标滚动缩放
      if (this.flag < 3) {
        var point = new BMap.Point(this.baiduLon, this.baiduLat);
        map.centerAndZoom(new BMap.Point(this.baiduLon, this.baiduLat), 17);
        var marker = new BMap.Marker(point, {
          enableDragging: true,
        });
        map.addOverlay(marker);
        map.addEventListener("click", function (e) {
          sendposition(e.point.lng, e.point.lat);
          map.clearOverlays();
          let point = new BMap.Point(e.point.lng, e.point.lat);
          let marker = new BMap.Marker(point, {
            enableDragging: true,
          });
          map.addOverlay(marker); //添加覆盖物
          marker.addEventListener("dragend", function (e) {
            sendposition(e.point.lng, e.point.lat);
          });
        });
        marker.addEventListener("dragend", function (e) {
          sendposition(e.point.lng, e.point.lat);
        });
      } else {
        // map.centerAndZoom(city, 17);// 修改当前坐标接收城市名称地址等传参 后面是缩放级别

        // 初始化地图， 设置中心点坐标和地图级别 可选点拖拽
        var myGeo = new BMap.Geocoder();
        myGeo.getPoint(city, function (point) {
          if (point) {
            sendposition(point.lng, point.lat);
            map.centerAndZoom(point, 17);
            let marker = new BMap.Marker(point, {
              enableDragging: true,
            });
            map.addOverlay(marker); //添加覆盖物
            // 点标记拖拽事件
            map.addEventListener("click", function (e) {
              sendposition(e.point.lng, e.point.lat);
              map.clearOverlays();
              let point = new BMap.Point(e.point.lng, e.point.lat);
              let marker = new BMap.Marker(point, {
                enableDragging: true,
              });
              map.addOverlay(marker); //添加覆盖物
              marker.addEventListener("dragend", function (e) {
                sendposition(e.point.lng, e.point.lat);
              });
            });
            marker.addEventListener("dragend", function (e) {
              sendposition(e.point.lng, e.point.lat);
            });
          } else {
            alert("您选择的地址没有解析到结果！");
          }
        });
      }
    },
  },
  mounted() {
    this.bdMap();
  },
  watch: {
    provinceList() {
      this.flag++;
      if (this.flag >= 2) {
        let nameArray = this.provinceList.map((item, index) => {
          if (index <= 1) {
            return item.name;
          } else {
            return "";
          }
        });
        let address = nameArray.join("") + this.address;
        this.bdMap(address);
      }
    },
    address() {
      this.flag++;
      if (this.flag >= 2) {
        let nameArray = this.provinceList.map((item, index) => {
          if (index <= 1) {
            if (item.name) {
              return item.name;
            } else {
              return item;
            }
          } else {
            return "";
          }
        });
        setTimeout(() => {
          let address = nameArray.join("") + this.address;
          this.bdMap(address);
        }, 0);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.baiduMap {
  width: 98%;
  height: 280px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 1% auto;
}
</style>
<style>
.bm-view {
  width: 100%;
  height: 300px;
}
</style>